import Vue from "vue";
import { getProps } from "./props";
import { getData } from "./data";
import { methods } from "./methods";
import { BlockInstanceProps, Props } from "@/types";
import { computed, mappedComputed } from "./computed";
import { MultiMotorSpecsVehicleStore } from "./types";
export { methods, getProps, getData, computed };
export type Props = ReturnType<typeof getProps>;
export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;
export type Computed = {
  [K in keyof typeof computed]: ReturnType<(typeof computed)[K]>;
} & ReturnType<typeof mappedComputed>;

export interface BlockMultiMotorSpecsProps {
  padding: Props.Str;
  enforceVerification: Props.Bool;
  enforceValidation: Props.Bool;
  invalidFeedback: Props.Str;
  clientId: Props.Str;
  clientSecret: Props.Str;
  requireConfirmation: Props.Bool;
  returnMercedesRecallData: Props.Bool;
  returnDpfData: Props.Bool;
  returnPriceEstimation: Props.Bool;
  stagingEnvironment: Props.Bool;
  allowManualKeeperEntry: Props.Bool;
  collectOwnershipPeriod: Props.Bool;
  returnSupplementaryDataFromUkvd: Props.Bool;
  returnSupplementaryVwDataFromEa: Props.Bool;
  allowMultiVehicle: Props.Bool;

  stores: Props.CGPropExtras<Array<{ name: MultiMotorSpecsVehicleStore; type: string }>>;
}
/**
 * Passing Computed as a generic prevents TS from throwing circular reference error when
 * computed functions use a state object with `BlockMultiMotorSpecsInstance` as its type
 */
export type BlockMultiMotorSpecsInstance = Vue &
  Data &
  Methods &
  Computed &
  BlockInstanceProps<BlockMultiMotorSpecsProps>;
