import { getClaimDataService } from "@/helpers/vue";
import { getFunnelVariables } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";

import { BlockMultiMotorSpecsInstance } from "./BlockMultiMotorSpecs";
import { resetToSearchAdditionalVehicleView, searchAdditionalVehicle } from "./methods";

export async function mounted(state: BlockMultiMotorSpecsInstance) {
  state.userId = state.userService.getUserId();

  getClaimDataService<BlockMultiMotorSpecsInstance>(state);

  resetToSearchAdditionalVehicleView(state, 0);
  await parseQueryParameters(state);

  if (state.vrm === "") {
    const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(
      getFirebaseBackend().firestore(),
      state.funnelId
    );

    state.vrm =
      state.claimDataService.getArtefact(funnelVariables.find((x) => x.field === "vehicleRegistration")?.id) ?? "";
  }
}

async function parseQueryParameters(state: BlockMultiMotorSpecsInstance): Promise<void> {
  if (Object.keys(state.$route.query)?.length > 0 && state.$route.query?.vehicleRegistration) {
    state.additionalVehicles[0].enteredRegistration = state.$route.query?.vehicleRegistration?.toString();
    state.ownershipDate = state.$route.query?.ownershipDate?.toString();
  }

  if (state.vrm && state.ownershipDate) {
    state.uiToggles.isSearchingByQueryParameters = true;
    await searchAdditionalVehicle(state, true, 0);
  }

  if (state.vrm && !state.ownershipDate) {
    state.uiToggles.isSearchingByQueryParameters = true;
    state.ownershipDate = new Date().toISOString();
    await searchAdditionalVehicle(state, true, 0);
  }
}
