<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, BlockMultiMotorSpecsInstance } from "./BlockMultiMotorSpecs";
import { Fragment } from "vue-frag";

// Components
import BlockButton from "@/components/shared/blocks/button.vue";

import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";

import { computed, mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockMultiMotorSpecs",
  components: {
    Fragment,
    BlockButton,
    BlockDatePicker,
    BlockSingleSelect,
    BlockPadding,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),

    finalLoaderState(): boolean {
      //@ts-ignore
      return computed.finalLoaderState(this as any as BlockMultiMotorSpecsInstance, this.loaders.next);
    },

    aComputed(): string {
      return computed.aComputed(this as unknown as BlockMultiMotorSpecsInstance);
    },
    isAddVehicleDisabled(): boolean {
      return this.additionalVehicles.some((vehicle) => vehicle.enteredRegistration.length > 7);
    },
  },
  data() {
    return { ...getData(this) };
  },

  watch: {
    padding: {
      handler(padding): void {
        for (const blockInput of Object.values(this.BlockInputs)) {
          blockInput.padding = padding;
        }
      },
      immediate: true,
    },

    "BlockInputs.currentOwnerSingleSelect.answer": function (newValue) {
      // If the prop to allow manual keeper entry is set to false, this logic should not run.
      if (!this.allowManualKeeperEntry) {
        return;
      }

      if (newValue === "Yes") {
        this.$nextTick(() => {
          this.uiToggles.showKeeperStartQuestion = true;
        });
        this.$nextTick(() => {
          this.uiToggles.showKeeperEndQuestion = false;
        });
      } else if (newValue === "No") {
        this.$nextTick(() => {
          this.uiToggles.showKeeperStartQuestion = true;
        });

        this.$nextTick(() => {
          this.uiToggles.showKeeperEndQuestion = true;
        });
      }
    },

    "BlockInputs.keeperSelectSingleSelect.answer": function (newValue) {
      if (!this.allowManualKeeperEntry) {
        return;
      }

      if (newValue !== "Other") {
        this.$nextTick(() => {
          this.BlockInputs.additionalCurrentOwnerSingleSelect.answer = null;
          this.BlockInputs.additionalPurchaseMethodSingleSelect.answer = null;
          this.BlockInputs.additionalPurchaseLocationSingleSelect.answer = null;

          this.BlockInputs.additionalCurrentOwnerSingleSelect.invalidFeedback = null;
          this.BlockInputs.additionalPurchaseMethodSingleSelect.invalidFeedback = null;

          this.BlockInputs.additionalCurrentOwnerSingleSelect.state = null;
          this.BlockInputs.additionalPurchaseMethodSingleSelect.state = null;
        });
      }
    },
  },

  methods: {
    async submitAdditionalVehiclePage(additionalVehicleIndex: number) {
      await methods.submitAdditionalVehiclePage(
        this as unknown as BlockMultiMotorSpecsInstance,
        additionalVehicleIndex
      );
      await this.$nextTick();
      this.scrollToTop();
    },
    scrollToTop() {
      console.log("scrollToTop");
      window.scrollTo(0, 0);
    },

    async submitAdditionalVehiclePageMethod(additionalVehicleIndex: number) {
      await methods.submitAdditionalVehiclePageMethod(
        this as unknown as BlockMultiMotorSpecsInstance,
        additionalVehicleIndex
      );
    },
    async searchAdditionalVehicle(additionalVehicleIndex: number) {
      await methods.searchAdditionalVehicle(
        this as unknown as BlockMultiMotorSpecsInstance,
        false,
        additionalVehicleIndex
      );
    },
    displayHelpModal() {
      methods.displayHelpModal(this as unknown as BlockMultiMotorSpecsInstance);
    },

    notMyVehicle(additionalVehicleIndex: number) {
      methods.notMyVehicle(this as unknown as BlockMultiMotorSpecsInstance, additionalVehicleIndex);
    },

    async addVehicle(): Promise<void> {
      await methods.addVehicle(this as unknown as BlockMultiMotorSpecsInstance);
    },

    async submitVehicleConfirmation(additionalVehicleIndex: number): Promise<void> {
      await methods.submitVehicleConfirmation(this as unknown as BlockMultiMotorSpecsInstance, additionalVehicleIndex);
    },

    async submitOwnershipDate(additionalVehicleIndex: number): Promise<void> {
      await methods.submitOwnershipDate(this as unknown as BlockMultiMotorSpecsInstance, true, additionalVehicleIndex);
    },

    removeVehicle(additionalVehicleIndex: number): void {
      this.additionalVehicles.splice(additionalVehicleIndex, 1);
      this.BlockInputs.searchAdditionalVehicleButton.isProcessing = false;
      this.BlockInputs.searchAdditionalVehicleButton.isDisabled = false;
    },
  },

  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockMultiMotorSpecsInstance);
  },
});
</script>

<style scoped>
.error {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #e13f45 !important;
  transition: 0.25s;
}
.selected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #3452e1 !important;
  transition: 0.25s;
}
.deselected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #eff2f7 !important;
  transition: 0.25s;
}

.input-group > .input-group-prepend {
  flex: 0 0 15%;
}
.input-group .input-group-text {
  width: 100%;
}
/* Reg Input styling */
.reg-input-selected {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.reg-input-selected:focus {
  border-color: var(--primary) !important;
}
.input-group-prepend:focus {
  border-color: var(--primary) !important;
}

.remove-reg {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.btn-glow,
.btn-glow:active,
.btn-glow:focus,
.btn-glow:hover {
  box-shadow: 0px 0px 30px -10px #3452e1 !important;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
.disabled {
  background-color: #e9ecef !important;
}

.is-invalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}

.form-control {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.prepend-border {
  border: 2px solid var(--primary) !important;
}
</style>
<template>
  <Fragment>
    <template v-if="uiToggles.additionalVehicles.showAdditionalVehicleSection">
      <div v-for="(additionalVehicle, additionalVehicleIndex) in additionalVehicles" :key="additionalVehicle.id">
        <template v-if="additionalVehicles[additionalVehicleIndex].view.lookup">
          <BlockPadding padding="20" />
          <div class="d-flex justify-content-between align-self-center">
            <!--
            <div class="d-flex flex-column">
              <h5 class="text-grey-900 font-weight-semibold mb-0 mt-1">Vehicle {{ additionalVehicleIndex + 2 }}</h5>
              <p class="mb-1 text-grey-700">Please enter your vehicle registration number</p>
            </div>
            -->
            <!--  No modal for the trash icon here because the client didnt input anything yet, so it's not an issue-->
            <!-- X Icon Fixed on the Right -->
            <template v-if="additionalVehicles.length === 1">
              <div class="d-flex flex-column">
                <h5 class="text-grey-900 font-weight-semibold mb-0 mt-1">Vehicle Registration</h5>
                <p class="mb-1 text-grey-700">Please enter your vehicle registration number</p>
              </div>
              <div class="ml-auto align-self-center">
                <b-button
                  size="lg"
                  class="mb-0 float-right text-md text-grey-600 p-0 hover-red-600"
                  variant="primary"
                  style="background: transparent; border: none"
                  @click="removeVehicle(additionalVehicleIndex)"
                  ><b-icon icon="x" scale="1.5" class="mx-0 icon"></b-icon>
                </b-button>
              </div>
            </template>
            <template v-if="additionalVehicles.length > 1">
              <h5 class="text-grey-900 font-weight-semibold mb-0 mt-1">Vehicle {{ additionalVehicleIndex + 1 }}</h5>
              <div class="ml-auto align-self-center">
                <b-button
                  size="lg"
                  class="mb-0 float-right text-md text-grey-600 p-0 hover-red-600"
                  variant="primary"
                  style="background: transparent; border: none"
                  @click="removeVehicle(additionalVehicleIndex)"
                  ><b-icon icon="x" scale="1.5" class="mx-0 icon"></b-icon>
                </b-button>
              </div>
            </template>
          </div>
          <BlockPadding padding="10" />

          <div class="mb-2">
            <b-input-group class="w-100">
              <b-input-group-prepend>
                <b-input-group-text class="justify-content-center reg-input-selected">
                  <img :src="require('@/assets/images/GB.png')" style="width: 30px" alt="GB" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                class="border reg-input-selected"
                size="lg"
                maxLength="20"
                v-model="additionalVehicles[additionalVehicleIndex].enteredRegistration"
                :state="additionalVehicles[additionalVehicleIndex].vrmInputState"
                placeholder="Example: AB12 CDE"
                @update="additionalVehicles[additionalVehicleIndex].vrmInputState = null"
              ></b-form-input>
            </b-input-group>
            <!-- Conditional Feedback Message Display on the Left -->
            <div class="flex-grow-1">
              <b-form-invalid-feedback
                :state="additionalVehicles[additionalVehicleIndex].state"
                v-if="
                  (additionalVehicles[additionalVehicleIndex].state === false &&
                    additionalVehicles[additionalVehicleIndex].invalidFeedback.length > 0 &&
                    additionalVehicles[additionalVehicleIndex].vrmInputState === false) ||
                  (additionalVehicles[additionalVehicleIndex].duplicateClaim &&
                    additionalVehicles[additionalVehicleIndex].invalidFeedback.length > 0)
                "
              >
                <p class="mb-0 text-danger">
                  {{ additionalVehicles[additionalVehicleIndex].invalidFeedback }}
                </p>
              </b-form-invalid-feedback>
            </div>

            <h5 class="mt-1 text-sm" role="button" @click="displayHelpModal">
              <span :style="theme.textColourStyles">For help finding your vehicle, click here</span>
            </h5>

            <b-modal id="helpModal" title="Find Your Vehicle" size="md" centered hide-footer>
              <template #modal-header="{ close }">
                <div style="display: block">
                  <h4>Find Your Vehicle</h4>
                </div>

                <div style="float: right">
                  <b-button size="lg" variant="link" @click="close()" class="p-0 pr-1">
                    <i class="fas fa-times mx-1" />
                  </b-button>
                </div>
              </template>

              <h5>Current Registrations</h5>
              <p>
                Make sure you search using the vehicle's unique registration plate rather than using your private plate
                unless this currently remains on the vehicle. If you are not the current owner, note that the current
                owner may have changed the Registration Number.
              </p>

              <h5>Common Mistakes</h5>
              <p>
                If your vehicle uses a standard registration plate, it should be in the format LLNN LLL. L - letter, N -
                number. Common mistakes can be swapping 0 for O, or I for 1.
              </p>

              <h5>Ownership Date</h5>
              <p>
                Please select any date on which you owned the vehicle or were the registered owner of the vehicle.
                Selecting the correct ownership date will help us find the correct vehicle associated with your
                registration number.
              </p>
            </b-modal>
          </div>
        </template>

        <template v-if="additionalVehicle.view.ownershipConfirmation">
          <BlockPadding padding="20" />
          <template v-if="additionalVehicles.length === 1">
            <h5 class="text-grey-900 font-weight-semibold mb-0 mt-1">Vehicle Registration</h5>
          </template>
          <template v-if="additionalVehicles.length > 1">
            <h5 class="text-grey-900 font-weight-semibold mb-0 mt-1">Vehicle {{ additionalVehicleIndex + 1 }}</h5>
          </template>
          <BlockPadding padding="10" />
          <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
            <b-card-body style="padding-bottom: 0.25rem !important">
              <h5 class="text-grey-700 font-weight-semibold">Vehicle Found</h5>
              <h5 class="text-grey-900">
                We found your vehicle,
                <b>{{ additionalVehicles[additionalVehicleIndex].make }}</b
                >, <b>{{ additionalVehicles[additionalVehicleIndex].model }}</b
                >, <b>{{ additionalVehicles[additionalVehicleIndex].yearOfManufacture }}</b
                >, <b>{{ additionalVehicles[additionalVehicleIndex].colour }}</b
                >,
                <b>{{ additionalVehicles[additionalVehicleIndex].fuelType }}</b>
              </h5>

              <h5 class="font-weight-semibold" role="button" @click="notMyVehicle(additionalVehicleIndex)">
                <span :style="theme.textColourStyles">Click here to change your vehicle</span>
              </h5>
            </b-card-body>
          </b-card>
          <BlockPadding :padding="padding" />
          <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Confirmation</h5>
          <p class="mb-1 text-grey-700">Please confirm the vehicle details shown above are correct</p>
          <BlockSingleSelect
            v-bind="BlockInputs.additionalVehicleConfirmationSingleSelect"
            v-bind.sync="BlockInputs.additionalVehicleConfirmationSingleSelect"
            padding="0"
            data-cy="motorspecs-additional-vehicle-confirmation-select"
          />

          <BlockButton
            @clicked="submitVehicleConfirmation(additionalVehicleIndex)"
            v-bind="BlockInputs.submitVehicleConfirmationButton"
            v-bind.sync="BlockInputs.submitVehicleConfirmationButton"
            data-cy="motorspecs-additional-vehicle-confirmation-button"
          />
        </template>

        <template v-if="additionalVehicle.view.historicalVinLookup">
          <BlockPadding :padding="padding" />
          <h5 class="mb-0 font-weight-semibold text-grey-900">Ownership Date</h5>
          <div class="d-flex justify-content-between align-items-center">
            <p class="mb-0 text-grey-700">Please select any date on which you were the owner of the vehicle.</p>
            <b-button
              size="lg"
              class="mb-0 float-right text-md text-grey-600 p-0 pb-2 hover-red-600"
              variant="primary"
              style="background: transparent; border: none"
              @click="removeVehicle(additionalVehicleIndex)"
              ><b-icon icon="x" scale="1.5" class="mx-0 icon"></b-icon>
            </b-button>
          </div>
          <BlockDatePicker
            v-bind="BlockInputs.additionalOwnershipDatePicker"
            :answer.sync="additionalVehicles[additionalVehicleIndex].ownershipDate"
            v-bind.sync="BlockInputs.additionalOwnershipDatePicker"
            padding="0"
            data-cy="motorspecs-additional-ownership-date-picker"
          />

          <BlockButton
            @clicked="submitOwnershipDate(additionalVehicleIndex)"
            v-bind="BlockInputs.submitOwnershipDateButton"
            v-bind.sync="BlockInputs.submitOwnershipDateButton"
            data-cy="motorspecs-additional-ownership-date-button"
          />
        </template>
        <template v-if="additionalVehicle.view.ownershipQuestions">
          <BlockPadding padding="20" />
          <template v-if="additionalVehicles.length === 1">
            <h5 class="text-grey-900 font-weight-semibold mb-0 mt-1">Vehicle Registration</h5>
          </template>
          <template v-if="additionalVehicles.length > 1">
            <h5 class="text-grey-900 font-weight-semibold mb-0 mt-1">Vehicle {{ additionalVehicleIndex + 1 }}</h5>
          </template>
          <BlockPadding padding="10" />
          <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
            <b-card-body style="padding-bottom: 0.25rem !important">
              <h5 class="text-grey-700 font-weight-semibold">Vehicle Found</h5>
              <h5 class="text-grey-900">
                We found your vehicle,
                <b>{{ additionalVehicles[additionalVehicleIndex].make }}</b
                >, <b>{{ additionalVehicles[additionalVehicleIndex].model }}</b
                >, <b>{{ additionalVehicles[additionalVehicleIndex].yearOfManufacture }}</b
                >, <b>{{ additionalVehicles[additionalVehicleIndex].colour }}</b
                >,
                <b>{{ additionalVehicles[additionalVehicleIndex].fuelType }}</b>
              </h5>

              <h5 class="font-weight-semibold" role="button" @click="notMyVehicle(additionalVehicleIndex)">
                <span :style="theme.textColourStyles">Click here to change your vehicle</span>
              </h5>
            </b-card-body>
          </b-card>
          <BlockPadding :padding="padding" />
          <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Date</h5>
          <p class="mb-1 text-grey-700">Please select the date when you purchased the vehicle</p>

          <BlockSingleSelect
            v-bind="BlockInputs.additionalKeeperSelectSingleSelect"
            v-bind.sync="BlockInputs.additionalKeeperSelectSingleSelect"
            v-model="BlockInputs.additionalKeeperSelectSingleSelect.answer"
            padding="0"
            data-cy="motorspecs-additional-keeper-select"
          />

          <template v-if="BlockInputs.additionalKeeperSelectSingleSelect.answer === 'Other'">
            <BlockPadding :padding="padding" />
            <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Ownership</h5>
            <p class="mb-1 text-grey-700">Are you still the current owner of the vehicle?</p>
            <BlockSingleSelect
              v-bind="BlockInputs.additionalCurrentOwnerSingleSelect"
              v-bind.sync="BlockInputs.additionalCurrentOwnerSingleSelect"
              padding="0"
              data-cy="motorspecs-additional-current-owner-select"
            />

            <template
              v-if="
                BlockInputs.additionalCurrentOwnerSingleSelect.answer === 'Yes' ||
                BlockInputs.additionalCurrentOwnerSingleSelect.answer === 'No'
              "
            >
              <BlockPadding :padding="padding" />
              <h5 class="mb-0 font-weight-semibold text-grey-900">Keeper Start Date</h5>
              <div class="ml-auto align-self-center">
                <b-button
                  size="lg"
                  class="mb-0 float-right text-md text-grey-600 p-0 hover-red-600"
                  variant="primary"
                  style="background: transparent; border: none"
                  @click="removeVehicle(additionalVehicleIndex)"
                  ><b-icon icon="x" scale="1.5" class="mx-0 icon"></b-icon>
                </b-button>
              </div>
              <p class="mb-1 text-grey-700">Please select the date when you became the keeper of the vehicle</p>
              <BlockDatePicker
                v-bind="BlockInputs.additionalKeeperStartDatePicker"
                v-bind.sync="BlockInputs.additionalKeeperStartDatePicker"
                padding="0"
                data-cy="motorspecs-additional-keeper-start-date-picker"
              />
            </template>
            <template v-if="BlockInputs.additionalCurrentOwnerSingleSelect.answer === 'No'">
              <BlockPadding :padding="padding" />
              <h5 class="mb-0 font-weight-semibold text-grey-900">Keeper End Date</h5>
              <p class="mb-1 text-grey-700">Please select the date when you ceased being the keeper of the vehicle</p>
              <BlockDatePicker
                v-bind="BlockInputs.additionalKeeperEndDatePicker"
                v-bind.sync="BlockInputs.additionalKeeperEndDatePicker"
                padding="0"
                data-cy="motorspecs-additional-keeper-end-date-picker"
              />
            </template>
          </template>
          <template
            v-if="
              BlockInputs.additionalKeeperSelectSingleSelect.answer === 'Other' &&
              ((BlockInputs.additionalKeeperStartDatePicker.answer &&
                BlockInputs.additionalCurrentOwnerSingleSelect.answer === 'Yes') ||
                (BlockInputs.additionalKeeperEndDatePicker.answer &&
                  BlockInputs.additionalKeeperStartDatePicker.answer &&
                  BlockInputs.additionalCurrentOwnerSingleSelect.answer === 'No'))
            "
          >
            <div>
              <BlockPadding :padding="padding" />
              <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Method</h5>
              <p class="mb-1 text-grey-700">Please select the method by which you purchased the vehicle</p>

              <BlockSingleSelect
                v-bind="BlockInputs.additionalPurchaseMethodSingleSelect"
                v-bind.sync="BlockInputs.additionalPurchaseMethodSingleSelect"
                padding="0"
                data-cy="motorspecs-additional-purchase-method-select"
              />
            </div>
            <div>
              <BlockPadding :padding="padding" />
              <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Location</h5>
              <p class="mb-1 text-grey-700">Please select the location where you purchased the vehicle</p>

              <BlockSingleSelect
                v-bind="BlockInputs.additionalPurchaseLocationSingleSelect"
                v-bind.sync="BlockInputs.additionalPurchaseLocationSingleSelect"
                padding="0"
                data-cy="motorspecs-additional-purchase-location-select"
              />

              <div class="d-flex justify-content-center">
                <BlockButton
                  @clicked="submitAdditionalVehiclePage(additionalVehicleIndex)"
                  v-bind="BlockInputs.submitAdditionalVehiclePageButton"
                  v-bind.sync="BlockInputs.submitAdditionalVehiclePageButton"
                  data-cy="motorspecs-additional-vehicle-submit-button"
                />
              </div>
            </div>
          </template>
          <template v-if="BlockInputs.additionalKeeperSelectSingleSelect.answer !== 'Other'">
            <div>
              <BlockPadding :padding="padding" />
              <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Method</h5>
              <p class="mb-1 text-grey-700">Please select the method by which you purchased the vehicle</p>

              <BlockSingleSelect
                v-bind="BlockInputs.additionalPurchaseMethodSingleSelect"
                v-bind.sync="BlockInputs.additionalPurchaseMethodSingleSelect"
                padding="0"
                data-cy="motorspecs-additional-purchase-method-select"
              />
            </div>
            <div>
              <BlockPadding :padding="padding" />
              <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Location</h5>
              <p class="mb-1 text-grey-700">Please select the location where you purchased the vehicle</p>

              <BlockSingleSelect
                v-bind="BlockInputs.additionalPurchaseLocationSingleSelect"
                v-bind.sync="BlockInputs.additionalPurchaseLocationSingleSelect"
                padding="0"
                data-cy="motorspecs-additional-purchase-location-select"
              />

              <div class="d-flex justify-content-center">
                <BlockButton
                  @clicked="submitAdditionalVehiclePage(additionalVehicleIndex)"
                  v-bind="BlockInputs.submitAdditionalVehiclePageButton"
                  v-bind.sync="BlockInputs.submitAdditionalVehiclePageButton"
                  data-cy="motorspecs-additional-vehicle-submit-button"
                />
              </div>
            </div>
          </template>
        </template>
        <template v-if="additionalVehicle.duplicateClaim && additionalVehicle.view.showCard">
          <BlockPadding padding="20" />
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="text-grey-900 font-weight-semibold my-2">Vehicle {{ additionalVehicleIndex + 1 }}</h5>
            <div class="d-flex justify-content-end my-2 ml-2">
              <b-button
                v-if="additionalVehicles.length >= 1"
                size="lg"
                class="mb-0 text-md text-grey-600 p-0 hover-red-600"
                variant="primary"
                style="background: transparent; border: none"
                @click="removeVehicle(additionalVehicleIndex)"
                ><b-icon icon="x" scale="1.5" class="icon"></b-icon>
              </b-button>
            </div>
          </div>
          <b-card-body class="no-body shadow-sm bg-warning-50 px-4 py-3">
            <!-- Display the inputted registration number -->
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <p class="text-warning-700 font-weight-semibold text-md mb-0">
                  <b>{{ additionalVehicles[additionalVehicleIndex].enteredRegistration }}</b>
                </p>
                <p class="text-warning-600 text-sm font-weight-semibold mb-0">
                  {{ additionalVehicles[additionalVehicleIndex].make }},
                  {{ additionalVehicles[additionalVehicleIndex].model }},
                  {{ additionalVehicles[additionalVehicleIndex].yearOfManufacture }},
                  {{ additionalVehicles[additionalVehicleIndex].colour }},
                  {{ additionalVehicles[additionalVehicleIndex].fuelType }},
                  {{ additionalVehicles[additionalVehicleIndex].purchaseMethod.toUpperCase() }},
                  {{ additionalVehicles[additionalVehicleIndex].vehicleAcquisitionLocation.toUpperCase() }}
                </p>
              </div>
              <!-- Check-circle Icon and trash can positioned to the right -->
              <div class="ml-auto">
                <b-icon icon="x-circle-fill" variant="warning" class="text-warning-600" font-scale="1.5"> </b-icon>
              </div>
            </div>
          </b-card-body>
          <div class="flex-grow-1">
            <b-form-invalid-feedback
              :state="additionalVehicles[additionalVehicleIndex].state"
              v-if="
                additionalVehicles[additionalVehicleIndex].state === false &&
                additionalVehicles[additionalVehicleIndex].invalidFeedback.length > 0 &&
                additionalVehicles[additionalVehicleIndex].duplicateClaim === true
              "
            >
              <p class="mb-0 text-warning">
                {{ additionalVehicles[additionalVehicleIndex].invalidFeedback }}
              </p>
            </b-form-invalid-feedback>
          </div>
        </template>

        <template
          v-if="additionalVehicle.view.success && additionalVehicle.view.showCard && !additionalVehicle.duplicateClaim"
        >
          <BlockPadding padding="20" />
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="text-grey-900 font-weight-semibold my-2">Vehicle {{ additionalVehicleIndex + 1 }}</h5>
            <div class="d-flex justify-content-end my-2 ml-2">
              <b-button
                v-if="additionalVehicles.length >= 1"
                size="lg"
                class="mb-0 text-md text-grey-600 p-0 hover-red-600"
                variant="primary"
                style="background: transparent; border: none"
                @click="removeVehicle(additionalVehicleIndex)"
                ><b-icon icon="x" scale="1.5" class="icon"></b-icon>
              </b-button>
            </div>
          </div>
          <b-card-body class="no-body shadow-sm bg-success-50 px-4 py-3">
            <!-- Display the inputted registration number -->
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <p class="text-success-700 font-weight-semibold text-md mb-0">
                  <b>{{ additionalVehicles[additionalVehicleIndex].enteredRegistration }}</b>
                </p>
                <p class="text-success-600 text-sm font-weight-semibold mb-0">
                  {{ additionalVehicles[additionalVehicleIndex].make }},
                  {{ additionalVehicles[additionalVehicleIndex].model }},
                  {{ additionalVehicles[additionalVehicleIndex].yearOfManufacture }},
                  {{ additionalVehicles[additionalVehicleIndex].colour }},
                  {{ additionalVehicles[additionalVehicleIndex].fuelType }},
                  {{ additionalVehicles[additionalVehicleIndex].purchaseMethod.toUpperCase() }},
                  {{ additionalVehicles[additionalVehicleIndex].vehicleAcquisitionLocation.toUpperCase() }}
                </p>
              </div>
              <!-- Check-circle Icon and trash can positioned to the right -->
              <div class="ml-auto">
                <b-icon icon="check-circle-fill" variant="success" class="text-success-600" font-scale="1.5"> </b-icon>
              </div>
            </div>
          </b-card-body>
        </template>
        <template v-if="!additionalVehicle.view.success && additionalVehicle.view.showCard">
          <BlockPadding padding="20" />
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="text-grey-900 font-weight-semibold my-2">Vehicle {{ additionalVehicleIndex + 1 }}</h5>
            <div class="d-flex justify-content-end my-2 ml-2">
              <b-button
                v-if="additionalVehicles.length >= 1"
                size="lg"
                class="mb-0 text-md text-grey-600 p-0 hover-red-600"
                variant="primary"
                style="background: transparent; border: none"
                @click="removeVehicle(additionalVehicleIndex)"
                ><b-icon icon="x" scale="1.5" class="icon"></b-icon>
              </b-button>
            </div>
          </div>
          <b-card-body class="no-body shadow-sm bg-success-50 px-4 py-3">
            <!-- Display the inputted registration number -->
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <p class="text-success-700 font-weight-semibold text-md mb-0">
                  <b>{{ additionalVehicles[additionalVehicleIndex].enteredRegistration }}</b>
                </p>
                <p class="text-success-600 text-sm font-weight-semibold mb-0">
                  {{ additionalVehicles[additionalVehicleIndex].make }},
                  {{ additionalVehicles[additionalVehicleIndex].model }},
                  {{ additionalVehicles[additionalVehicleIndex].yearOfManufacture }},
                  {{ additionalVehicles[additionalVehicleIndex].colour }},
                  {{ additionalVehicles[additionalVehicleIndex].fuelType }},
                  {{ additionalVehicles[additionalVehicleIndex].purchaseMethod.toUpperCase() }},
                  {{ additionalVehicles[additionalVehicleIndex].vehicleAcquisitionLocation.toUpperCase() }}
                </p>
              </div>
              <!-- Check-circle Icon and trash can positioned to the right -->
              <!-- <div class="ml-auto">
                <b-icon icon="check-circle-fill" variant="success" class="text-success-600" font-scale="1.5"> </b-icon>
              </div> -->
            </div>
          </b-card-body>
        </template>
      </div>

      <div class="d-grid gap-2 col-5 mx-auto">
        <BlockButton
          @clicked="addVehicle"
          v-bind="BlockInputs.addVehicleButton"
          v-bind.sync="BlockInputs.addVehicleButton"
          v-if="
            additionalVehicles.length === 0 ||
            additionalVehicles.filter((vehicle) => vehicle.view.success || vehicle.view.showCard).length ===
              additionalVehicles.length
          "
          data-cy="motorspecs-add-additional-vehicle-button"
        />

        <BlockButton
          @clicked="submitAdditionalVehiclePageMethod"
          v-bind="BlockInputs.submitAdditionalVehicleFinalPageButton"
          v-bind.sync="BlockInputs.submitAdditionalVehicleFinalPageButton"
          v-if="
            additionalVehicles.filter((vehicle) => vehicle.view.success || vehicle.view.showCard).length ===
              additionalVehicles.length || additionalVehicles.length === 0
          "
          :isProcessing="finalLoaderState"
          padding="10"
          data-cy="motorspecs-submit-additional-vehicle-final-page-button"
        />

        <BlockButton
          @clicked="
            searchAdditionalVehicle(
              additionalVehicles.findIndex((vehicle) => vehicle.view.lookup && !vehicle.duplicateClaim)
            )
          "
          v-bind="BlockInputs.searchAdditionalVehicleButton"
          v-bind.sync="BlockInputs.searchAdditionalVehicleButton"
          v-if="additionalVehicles.filter((vehicle) => vehicle.view.lookup && !vehicle.duplicateClaim).length > 0"
          data-cy="motorspecs-additional-vehicle-search-button"
        />
      </div>
      <div class="flex-grow-1">
        <b-form-invalid-feedback :state="error === '' ? true : false" v-if="error.length > 0">
          <p class="mb-0 text-danger">
            {{ error }}
          </p>
        </b-form-invalid-feedback>
      </div>
    </template>
  </Fragment>
</template>
