import { navigationComputed, eventComputed, themesComputed, formComputed } from "@/state/helpers";
import { BlockMultiMotorSpecsInstance } from "./BlockMultiMotorSpecs";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
    ...(themesComputed as Record<keyof typeof themesComputed, any>),
    ...(formComputed as Record<keyof typeof formComputed, any>),
  };
}

export const computed = {
  aComputed,
  finalLoaderState,
};

function aComputed(state: BlockMultiMotorSpecsInstance): string {
  return "";
}

/** The logic for determining if the final page button should be disabled */
function finalLoaderState(state: BlockMultiMotorSpecsInstance, formLoader?: boolean): boolean {
  // If the form is processing but we are not return true
  if (formLoader && !state.BlockInputs.submitAdditionalVehicleFinalPageButton.isProcessing) {
    return true;
  }

  // If we are processing but the form is not return true
  if (state.BlockInputs.submitAdditionalVehicleFinalPageButton.isProcessing && !formLoader) {
    return true;
  }

  // If we are processing and the form is processing return true
  if (state.BlockInputs.submitAdditionalVehicleFinalPageButton.isProcessing && formLoader) {
    return true;
  }

  return false;
}
