import { getFirebaseBackend } from "@/authUtils";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { MotorSpecsBlockProps, blocks } from "./blocks";
import { FunnelsService, Milberg } from "@claimsgate/core";
import { MotorSpecsPartialVehicleStore } from "./types";

import { InfoModalService } from "../../global/informationModal/InfoModalService";
import Vue from "vue";

interface Vehicle {
  vrm: string;
  ownershipDate: string;
  purchaseMethod: string;
  state: boolean | null;
  invalidFeedback: string;
  vrmInputState: boolean | null;
  vrmInputInvalidFeedback: string;
  keeperChangeHistory: { startDate: string; endDate: string }[];
}

interface MotorSpecsPartialAdditionalVehicleStore extends MotorSpecsPartialVehicleStore {
  duplicateClaim?: boolean;
  vehicleCaseType?: string;
  vrm?: string;
  id: string;
  isEligible?: boolean;
  defender?: string;
  contactEmail?: string;
  enteredRegistration: string;
  ownershipDate: string;
  purchaseMethod: string;
  vehicleAcquisitionLocation: string;
  keeperStartDate: null | string;
  keeperEndDate: null | string;
  state: boolean | null;
  invalidFeedback: string;
  vrmInputState: boolean | null;
  vrmInputInvalidFeedback: string;
  milbergVehicleEligible?: boolean;
  milbergVehicleRejectionTag?: string;
  milbergVehicleRejectionExplanation?: string;
  tlwVehicleEligible?: boolean;
  tlwRejectionTag?: string;
  tlwRejectionExplanation?: string;
  view: {
    lookup: boolean;
    ownershipQuestions: boolean;
    ownershipConfirmation: boolean;
    historicalVinLookup: boolean;
    success: boolean;
    showCard: boolean;
  };
}

export function getData(state: Vue) {
  return {
    additionalVehicleIndex: 0,
    additionalVehicle: null as MotorSpecsPartialAdditionalVehicleStore,
    additionalVehicles: [
      {
        vrm: "",
        id: "", //unique id for each vehicle
        enteredRegistration: "",
        ownershipDate: "",
        isEligible: false,
        purchaseMethod: "",
        vehicleAcquisitionLocation: "",
        keeperStartDate: "",
        keeperEndDate: "",
        numberOfPreviousKeepers: 0,
        state: null,
        invalidFeedback: "",
        vrmInputState: null,
        vrmInputInvalidFeedback: "",
        keeperChangeHistory: [],
        milbergVehicleEligible: false,
        milbergVehicleRejectionTag: "",
        milbergVehicleRejectionExplanation: "",
        tlwVehicleEligible: false,
        tlwRejectionTag: "",
        tlwRejectionExplanation: "",
        view: {
          lookup: true,
          ownershipQuestions: false,

          ownershipConfirmation: false,
          historicalVinLookup: false,
          success: false,
          showCard: false,
        },
        duplicateClaim: false,
      },
    ] as MotorSpecsPartialAdditionalVehicleStore[],
    vrm: "",
    id: "",
    enteredRegistration: "",
    isEligible: false,
    ownershipDate: "",
    purchaseMethod: "",
    vehicleAcquisitionLocation: "",
    vrmInputState: null as boolean | null,
    userId: null as string,
    claimId: state.$route.params.claimId,
    pageId: state.$route.params.pageId,
    funnelId: state.$route.params.funnelId,
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    variablesService: new VariableService(getFirebaseBackend().firestore()),
    claimDataService: null as ClaimDataService | null,
    Milberg,
    infoModalService: new InfoModalService(state.$infoModal),
    userService: getUserHelper(),
    uiToggles: {
      process: {
        isAutoSearchingWithoutOwnershipDate: false,
      },
      isSearchingByQueryParameters: false,
      isVehicleSearchVisible: true,
      isKeeperSelectVisible: false,
      showVehicleConfirmation: false,
      showKeeperStartQuestion: false,
      showKeeperEndQuestion: false,
      showOwnershipDateQuestion: false,
      isLoading: false,
      isSubmitting: false,
      showFirstVehicle: false,
      showAdditionalVehicleOwnershipDateQuestion: false,
      showAdditionalVehicleConfirmation: false,

      additionalVehicles: {
        showAdditionalVehicleSection: false,
      },
    },
    showError: false,
    error: "",
    BlockInputs: {
      ...UserVariableBlocksService.getButtons(blocks.buttons as any),
      ...blocks.inputs,
      ...blocks.texts,
    } as MotorSpecsBlockProps,
    vehicle: null as MotorSpecsPartialVehicleStore,
    vrmInputInvalidFeedback: "",
    uiMessages: {
      keeperEndDateBeforeStartDate: "The keeper end date must be after the keeper start date",
      provenioOriginalOwner:
        "Unfortunately, you must be the original or second owner of the vehicle to join the claim.",
      provenioPurchasedBetween2013And2019:
        "Unfortunately, your vehicle must be purchased your vehicle between 2013 and 2019 to qualify to join the claim.",
      provenioLandRoverRangeRover:
        "Unfortunately, your vehicle must have been manufactured by Land Rover to join the claim.",

      notDiesel: "Unfortunately, your vehicle must be Diesel to qualify to to make a claim.",
      quantum2009To2019:
        "Unfortunately, your vehicle must be manufactured between 2009 and 2019 to qualify to make a claim.",
      jonesWhyte2008To2018:
        "Unfortunately, your vehicle must be manufactured between 2008 and 2018 to qualify to make a claim.",
      lanierNotPurchasedBefore2020: "To register, you must have purchased the vehicle before 2020.",
      milberg4YearsAfterManufacture:
        "To register, your vehicle must have been manufactured within 4 years of ownership.",
      milbergVehicleNotOwnedIn2017: "To register, you must have purchased the vehicle between July 2017 and 2019.",
      milbergNotVauxhallOrMercedes: "To register, your vehicle must be a Vauxhall or Mercedes.",
      milbergNotDiesel: "To register, the vehicle must have a Diesel engine.",
      vehicleDoesNotQualify: "Unfortunately, your vehicle does not qualify to to make a claim.",
      enterReg: "Please enter a valid registration number",
      noVehicleFound: "Unfortunately, we could not find your vehicle. Please try entering the VRM again.",
      doubleCheckOwnershipDate: "Please double check you owned the vehicle during this date",
      errorFindingVehicle:
        "There was an error finding the data for your vehicle, please try again later or use a different registration number and ownership date.",
      noVehicleSelected: "Please select the vehicle you owned",
      noKeeperSelected: "Please select the date you acquired the vehicle",
      noOwnershipDateSelected: "Please select a date in which you owned the vehicle",
      noPurchaseMethodSelected: "Please select the method by which you purchased the vehicle",
      noKeeperStartDateSelected: "Please select the date you acquired the vehicle",
      noKeeperEndDateSelected: "Please select the date you ceased being the keeper of the vehicle",
      ownershipDateInFuture: "Please select a date which has already taken place",
      noVehicleConfirmationSelected: "Please confirm whether the above vehicle is your vehicle.",
      duplicateVehicleRegistration: "Sorry, you have already added this vehicle.",
      duplicateClaim: "You have already registered a claim for this vehicle.",
      firstVehicleMustBeEligible: "If you just have one vehicle, it must be eligible to proceed.",
      oneVehicleMustBeEligible: "You must have at least one eligible vehicle to proceed.",
      pcpMustBePurchasedBefore2021:
        "Unfortunately, to register a claim, you must have purchased your vehicle before 28th January 2021.",
      dpfAffectedVehicleRequired:
        "Unfortunately, your vehicle is not affected by the Diesel Particulate Filter (DPF) issue.",
      vehicleNotPurchaseAfter2018:
        "Unfortunately, to register a claim, you must have purchased your vehicle between July 2017 and 2019.",
    },
  };
}
